import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet';

import SEO from "../components/SEO"
import Layout from "../components/layout/Layout"
import styles from "../css/index-css-modules.module.css"
import CustomCarousel from "../components/Carousel"
import { ProductCard, TestimonialCard } from "../components/Card"
import { BannerMultipleImages } from "../components/Banner"
import { 
    ResponsiveRow, 
    SectionWrapper, 
    SectionInnerWrapper, 
    SectionWrapperGray,
    CommonContentWrapper, 
    CommonContentDivider,
    AlignRight, 
    SectionHeader, 
    SectionSubHeader 
} from "../components/Wrapper"

const IndexPage = ({ data }) => {
    var showdown = require('showdown'),
        converter = new showdown.Converter();

    return (
        <Layout>
            <Helmet>
                <html lang="en" />
                <title>All Ins Agency</title>
                <meta name="description" content="All Ins Agency" />
                <meta name="google-site-verification" content="iKk2WwaMIRupBMVUDzn5zyaYsqIIUcTxi_e-8c5tchw" />
            </Helmet>
            <BannerMultipleImages
                banners={data.allStrapiBanner.edges.map(x => (
                    {
                        image: data.site.siteMetadata.apiUrl + x.node.fields.imageUrl,
                        heading: x.node.heading
                    }
                ))} />
            <SectionWrapper>
                <SectionInnerWrapper>
                    <ResponsiveRow>
                        <AlignRight className="flex flex-row">
                            <div className="relative border-r-4 border-solid border-primary px-4 sm:pr-8 sm:pl-24">
                                <AlignRight>
                                    <h2 className={styles.mainServiceHeader}>
                                        {data.allStrapiAnnouncement.edges[0].node.title}
                                    </h2>
                                </AlignRight>
                                <AlignRight>
                                    <h2 className={styles.mainServiceHeader}>
                                        {data.allStrapiAnnouncement.edges[0].node.title2}
                                    </h2>
                                </AlignRight>
                                <div className={styles.mainServiceIconWrapper}>
                                    <span className="ti-announcement"></span>
                                </div>
                            </div>
                        </AlignRight>
                        <div className={`pt-8 md:pt-0 ${styles.mainServiceContent}`}>
                            {data.allStrapiAnnouncement.edges[0].node.body}
                        </div>
                    </ResponsiveRow>
                    <CommonContentDivider></CommonContentDivider>
                    <div className="mt-4 mx-2 md:mx-4 lg:mx-8">
                        <div className="grid grid-cols-1 col-gap-8 row-gap-6 md:grid-cols-3">
                            {data.allStrapiProduct.edges.map((x, i) => (
                                <ProductCard key={i} title={x.node.name} index={x.node.id} iconType={x.node.icontype} iconName={x.node.iconname} />
                            ))}
                        </div>
                    </div>
                </SectionInnerWrapper>
            </SectionWrapper>
            <SectionWrapperGray>
                <SectionInnerWrapper>
                    <SectionSubHeader>PEOPLE SAY</SectionSubHeader>
                    <SectionHeader>TESTIMONIALS</SectionHeader>
                    <CommonContentWrapper>
                        <div
                            dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.allStrapiPage.edges[0].node.body) }}
                        />
                    </CommonContentWrapper>
                    <CommonContentDivider></CommonContentDivider>
                    <CustomCarousel 
                        autoPlaySpeed={8000}
                        items={data.allStrapiTestimonial.edges} 
                        showDots={true} 
                        numOfItemsToShow={[2, 2, 1]}
                    >
                        {data.allStrapiTestimonial.edges.map((x, i) => (
                            <TestimonialCard key={i} testimony={x.node.testimony} name={x.node.name} designation={x.node.designation} company={x.node.company} imageUrl={data.site.siteMetadata.apiUrl + x.node.fields.imageUrl} />
                        ))}
                    </CustomCarousel>
                </SectionInnerWrapper>
            </SectionWrapperGray>
        </Layout >
    )
}

export default IndexPage

export const indexQuery = graphql`
    query IndexQuery {
        allStrapiBanner(filter: {page: {eq: "/index"}}, sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    subheading
                    heading
                    name
                    page
                    fields {
                        imageUrl
                        sortindexnum
                    }
                }
            }
        }
        allStrapiTestimonial(sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    fields {
                        imageUrl
                        sortindexnum
                    }
                    company
                    designation
                    name
                    testimony
                }
            }
        }
        allStrapiAnnouncement(limit: 1) {
            edges {
                node {
                    body
                    title
                    title2
                }
            }
        }
        allStrapiProduct(sort: {fields: fields___sortindexnum, order: ASC}, filter: {showonhomepage: {eq: true}}) {
            edges {
                node {
                    id
                    name
                    category
                    icontype
                    iconname
                    showonhomepage
                    fields {
                        sortindexnum
                    }
                }
            }
        }
        allStrapiPage(filter: {name: {eq: "testimonials"}}) {
            edges {
                node {
                    name
                    body
                }
            }
        }
        site {
            siteMetadata { 
                apiUrl
            }
        }
    }
`
